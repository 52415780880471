import { StyledSvg } from "baseui/icon";
import React from "react";

export const ShiftIcon = (props: React.ComponentProps<typeof StyledSvg>) => (
  <StyledSvg viewBox="0 0 30 30" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15 4.5L24.5 14.8773H19.3455V25.5H10.6545V14.8773H5.5L15 4.5ZM12.4768 14.781V23.6333H17.5232V14.781C17.5233 14.3115 17.7005 13.8612 18.0159 13.5292C18.0592 13.4836 18.1046 13.4408 18.1519 13.4009C18.4491 13.1495 18.8204 13.0106 19.2054 13.0106H20.2928L15 7.22896L9.70717 13.0106H10.7946C11.1796 13.0106 11.5509 13.1495 11.8481 13.4009C11.8954 13.4408 11.9408 13.4836 11.984 13.5292C12.2995 13.8612 12.4767 14.3115 12.4768 14.781Z"
    />
  </StyledSvg>
);
